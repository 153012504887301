const initialState = {
    receivingSms:[],
    selectedRecSmsList: [],
    selectedRecSms: [],
    choosenSmsId:[],
    chosenNumber:[],
    smsArchives:[],
    sendSmsArchive:null,
    senderIDs:[]

}

const receivingSms = (state = initialState, action, mode) => {
    switch (action.type) {
        case 'SET_RECEIVING_SMS':
            return {
                ...state,
                receivingSms: action.receiving_sms
            }
        case 'SET_SELECTED_REC_SMS':
            return {
                ...state,
                selectedRecSms: action.selectedRecSms,
                mode: action.mode
            }
        case 'SET_SELECTED_REC_SMS_ID_LIST':
            return {
                ...state,
                selectedRecSmsList: action.selectedRecSmsList,
                mode: action.mode
            }
        case 'SET_CHOOSEN_SMS_ID':
            return {
                ...state,
                choosenSmsId: action.choosenSmsId,
                mode: action.mode
            }
        case 'SET_CHOSEN_NUMBER':
            return {
                ...state,
                chosenNumber: action.chosenNumber
            }
        case 'SET_SMS_ARCHIVES':
            return {
                ...state,
                smsArchives: action.smsArchives
            }
        case 'SET_SEND_SMS_ARCHIVES':
            return {
                ...state,
                sendSmsArchive: action.sendSmsArchive
            }
        case 'SET_SENDER_SMS_IDS':
            return {
                ...state,
                senderIDs:action.senderIDs
            }
        default:
            return state
    }
}

export default receivingSms