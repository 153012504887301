const initialState = {
    showBillingHistory: {
        data:[]
    },
    choosenBilling: []

}

const billingHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BILLING_HISTORY':
            return {
                ...state,
                showBillingHistory: action.billing
            }
        case 'SET_CHOOSEN_BILLING':
            return {
                ...state,
                choosenBilling: action.choosenBilling
            }
        default:
            return state
    }
}

export default billingHistoryReducer