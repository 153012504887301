const d = new Date()

const initialState = {
    usersReports: {},
    reportTypes: [],
    statusNames: [],
    selectedReportType: null,
    senderIDs:[],
    selectedSenderID:null,
    report_from: new Date(d.getFullYear(), d.getMonth(), 1, 12).toISOString().substr(0, 10),
    report_to: new Date(d.getFullYear(), d.getMonth() + 1, 0, 12).toISOString().substr(0, 10)
}

const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_REPORT_LIST':
            return {
                ...state,
                usersReports: action.reports
            }
        case 'SET_REPORT_TYPES':
            return {
                ...state,
                reportTypes: action.reportTypes
            }
        case 'SET_STATUS_NAMES':
            return {
                ...state,
                statusNames: action.statusNames
            }
        case 'SET_SELECTED_REPORT_TYPE':
            return {
                ...state,
                selectedReportType: action.selectedReportType
            }
        case 'SET_REPORT_FROM':
            return {
                ...state,
                report_from: action.report_from
            }
        case 'SET_REPORT_TO':
            return {
                ...state,
                report_to: action.report_to
            }
        case 'SET_SENDER_IDS_REPORTS':
            return {
                ...state,
                senderIDs:action.senderIDs
            }
        case 'SET_SELECTED_SENDER_ID':
            return {
                ...state,
                selectedSenderID:action.selectedSenderID
            }
        default:
            return state
    }
}

export default supportReducer