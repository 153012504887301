const initialState = {
    packageData: []
}

const packageList = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PACKAGES':
            return {
                ...state,
                packageData: action.packagesList
            }
    
        default:
            return state
    }
}

export default packageList