const initialState = {
    groups: [],

    currentGroupPage: 1,
    groupsMaxItem: 10,

    selectedGroup: null,
    selectMode: null,

    groupContacts: {},

    selectedContactList:[]
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_GROUPS_CONTACT':
            return {
                ...state,
                groups:action.groups
            }
        case 'ADD_GROUP':
            return {
                ...state,
                groups: [...state.groups, action.group]
            }
        case 'SET_CURRENT_GROUP_PAGE':
            return {
                ...state,
                currentGroupPage: action.page
            }
        case 'SET_SELECTED_GROUP_OBJECT':
            return {
                ...state,
                selectedGroup: action.group,
                selectMode: action.mode,
                selectedContact: action.selectedContact
            }
        case 'SET_GROUP_CONTACTS':
            return {
                ...state,
                groupContacts: {...state.groupContacts, [action.groupId]: action.contacts}
            }
        case 'SET_SELECTED_CONTACT_ID_LIST':
            return {
                ...state,
                selectedContactList: action.selectedContactList
            }
        default:
            return state
    }
}

export default authReducer
