const initialState = {
    jobsHistory:
        {
        data: []
    },
    currentJobPage: 1,
    jobsMaxItem: 3
    // selectedJob: null
}

const jobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_JOBS_HISTORY':
            return {
                ...state,
                jobsHistory: action.jobsHistory
            }
        case 'SET_CURRENT_JOB_PAGE':
            return {
                ...state,
                currentJobPage: action.page
            }
        default:
            return state
    }
}

export default jobsReducer