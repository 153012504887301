const initialState = {
    sendingForm: [],
    listTickets:[],
    selectedTickets:[],
    selectTickets:[],
    ticketDetail:{},
    conversationsTicket:[],
    showModal:false

}

const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LIST_TICKETS':
            return {
                ...state,
                listTickets: action.support
            }
        case 'ADD_TICKETS':
            return {
                ...state,
                sendingForm: action.sendingForm
            }
        case 'SET_SELECTED_TICKETS':
            return {
                ...state,
                selectedTickets: action.selectedTickets
            }
        case 'SET_SELECT_TICKETS':
            return {
                ...state,
                selectTickets: action.selectTickets,
                mode:action.mode
            }
        case 'SET_TICKET_DETAIL':
            return {
                ...state,
                ticketDetail: action.support,
                mode: action.mode
            }
        case 'SET_TICKET_CONVERSATIONS':
            return {
                ...state,
                conversationsTicket: action.support
            }
        case 'SET_SHOW_MODAL':
            return {
                ...state,
                showModal: action.showModal
            }
        default:
            return state
    }
}

export default supportReducer