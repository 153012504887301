const initialState = {
    senderIDs: [],
    selectedSenderId: null
}

const senderIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SENDER_ID':
            return {
                ...state,
                senderIDs:action.sender_id
            }
        case 'SET_SELECTED_SENDER_ID':
            return {
                ...state,
                selectedSenderId: action.selectedSenderId,
                mode:action.mode
            }
        default:
            return state
    }
}

export default senderIdReducer

