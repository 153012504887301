const initialState = {
    choosenCampaigns: {},
    campaigns: [],
    campaignDetail: [],
    selectedCampaign: null,
    selectedCampaigns: null,
    upComingCampaign: [],
    campaignArchive :
        {
        data: []
    },
    speed: {

    }
}

const campaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CAMPAIGNS':
            return {
                ...state,
                campaigns: action.campaign
            }
        case 'SET_CHOOSEN_CAMPAIGN':
            return {
                ...state,
                choosenCampaigns: action.choosenCampaigns,
                mode: action.mode
            }
        case  'SET_SELECTED_CAMPAIGNS':
            return {
                ...state,
                selectedCampaigns: action.selectedCampaigns,
                mode:action.mode
            }
        case 'SET_CAMPAIGN':
            return {
                ...state,
                campaignDetail: action.campaign,
                selectedCampaign: action.selectedCampaign
            }
        case 'SET_SELECTED_CAMPAIGN':
            return {
                ...state,
                selectedCampaign: action.selectedCampaign,
                mode: action.mode
            }
        case 'SET_CAMPAIGN_ARCHIVE':
            return {
                ...state,
                campaignArchive: action.campaignArchive
            }
        case 'GET_UPCOMING_CAMPAIGN':
            return {
                 ...state,
                 upComingCampaign: action.upComingCampaign
            }
        default:
            return state
    }
}

export default campaignReducer