const initialState = {
    announcements: [],
    newCount: 0
}

const billingHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ANNOUNCEMENTS':
            return {
                ...state,
                announcements: action.announcements,
                newCount: action.newCount
            }
        default:
            return state
    }
}

export default billingHistoryReducer