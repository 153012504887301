const initialState = {
    templatesList: [],
    selectedTemplates: [],
    selectTemplates: [],
    editTemplate: [],
    select_all: false
}

const smsTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SMS_TEMPLATES':
            return {
                ...state,
                templatesList: action.templatesData
            }
        case 'ADD_TEMPLATE':
            return {
                ...state,
                templatesList: action.templatesData
            }
        case 'SET_SELECTED_TEMPLATES':
            return {
                ...state,
                selectedTemplates: action.selectedTemplates,
                mode: action.mode
            }
        case 'SET_SELECT_TEMPLATES':
            return {
                ...state,
                selectTemplates: action.selectTemplates,
                mode: action.mode
            }
        case 'SET_EDIT_TEMPLATE':
            return {
                ...state,
                editTemplate: action.editTemplate,
                mode: action.mode
            }
        case 'SET_UPDATE_TEMPLATE':
                return {
                    ...state,
                    editTemplate: action.editTemplate,
                    name: action.name,
                    message:action.message,
                    mode: action.mode
                }
        case 'SET_SELECT_ALL':
            return {
                ...state,
                select_all: !state.select_all
            }
        
        default:
            return state
    }
}

export default smsTemplateReducer