const initialState = {
    showBlacklist: [],
    senderIDs:[],
    selectedSenderIDs:null,
    selectedBlacklist: [],
    selectBlacklist: [],
    chosenBlacklistId:[]
}

const blacklistReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BLACKLIST':
            return {
                ...state,
                showBlacklist: action.blacklist
            }
        case 'ADD_BLACKLIST':
            return {
                ...state,
                showBlacklist: action.blacklist
            }
        case 'SET_SENDER_IDS_BLACKLIST':
            return {
                ...state,
                senderIDs:action.senderIDs
            }
        case 'SET_SELECTED_SENDER_IDS':
            return {
                ...state,
                selectedSenderIDs:action.selectedSenderIDs,
                mode: action.mode
            }
        case 'SET_SELECTED_BLACKLIST':
            return {
                ...state,
                selectedBlacklist: action.selectedBlacklist,
                mode: action.mode
            }
        case 'SET_SELECT_BLACKLIST':
            return {
                ...state,
                selectBlacklist: action.selectBlacklist,
                mode: action.mode
            }
        case 'SET_CHOSEN_BLACKLIST_ID':
            return {
                ...state,
                chosenBlacklistId: action.chosenBlacklistId,
                mode: action.mode
            }
        default:
            return state
    }
}

export default blacklistReducer