const initialState = {
    shortCutsData: []
}

const shortCuts = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USER_FAVORITE_LINKS':
            return {
                ...state,
                shortCutsData: action.shortCutsData
            }
        default:
            return state
    }
}

export default shortCuts