const initialState = {
    fieldsList: null 
}

const smsTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FIELDS':
            return {
                ...state,
                fieldsList: action.fieldsData
            }
        default:
            return state
    }
}

export default smsTemplateReducer
