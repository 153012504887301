const initialState = {
    walletHistory: [],
    whiteList:[],
    webHooksList:[],
    userPackage:[],
    userDashbboardPackage:[]
}

const walletHistory = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_PACKAGE_HISTORY':
            return {
                ...state,
                userPackage: action.userPackage
            }
        case 'SET_DASHBOARD_PACKAGES':
            return {
            ...state,
             userDashbboardPackage: action.userPackage
        }
        case 'SET_WALLET_HISTORY':
            return {
                ...state,
                walletHistory: action.settings
            }

        case 'SET_WEB_HOOKS':
            return {
                ...state,
                webHooksList: action.webHooks
            }

        case 'SET_WHITE_LIST':
            return {
                ...state,
                whiteList: action.whiteListData
            }
        default:
            return state
    }
}

export default walletHistory